import React, { useEffect } from 'react';  
import { Route,Redirect } from 'react-router-dom';  
import { WebNav } from '../components/WebNav';
import { IonContent, IonPage, IonTitle, IonToolbar, IonButtons, IonFooter } from '@ionic/react';

export const Wrapper = ({children}) => {  

  return (  
    <>
    	 
      <IonContent fullscreen>
        <IonToolbar>
          <IonTitle>Select My Universities</IonTitle>
          <IonButtons slot="end">
            <WebNav />
          </IonButtons>
        </IonToolbar>
          {children}  
      </IonContent>
        
    </>
  )  
}  
