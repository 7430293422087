import { IonSlides, IonSlide, IonImg } from '@ionic/react';
import React,{useEffect} from 'react';

export const Slider: React.FC = () => {
    useEffect(()=>{
        const slides = document.getElementById('slides')
        slides.options = {
            spaceBetween: 40,
            autoplay: {
              delay: 2000,
            },
        }
    },[])
  return (
    <IonSlides pager="true" id="slides">
        <IonSlide>
            <IonImg src="https://royalnextacademy.com/frontend/images/1.png" className="slider"></IonImg>
        </IonSlide>
        
        <IonSlide>
            <IonImg src="https://royalnextacademy.com/frontend/images/banner3.png" className="slider"></IonImg>
        </IonSlide>
    </IonSlides>
  );
};

