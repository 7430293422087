// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDuJWRbmhNIm42tlCs0daknPX67I3hqiWg",
  authDomain: "selectmyuniversity-9ef71.firebaseapp.com",
  databaseURL: "https://selectmyuniversity-9ef71-default-rtdb.firebaseio.com",
  projectId: "selectmyuniversity-9ef71",
  storageBucket: "selectmyuniversity-9ef71.appspot.com",
  messagingSenderId: "744767252314",
  appId: "1:744767252314:web:f614845e3eae4e49c3f563",
  measurementId: "G-PWJQ37YW4H"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

firebase.auth = getAuth();

firebase.db = getFirestore(firebase);

export default firebase


