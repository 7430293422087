import React,{useEffect,useContext,useState} from 'react'
import { IonContent,IonSearchbar,IonHeader,IonToolbar,IonList,IonItem } from '@ionic/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { APIContext } from '../context/context';
import Loader from "react-loader-spinner";

export const MciList = () => {
	
	const [firebase] = useContext(APIContext);
	const [universities,setUniversities] = useState([])
	const [spinner,setSpinner] = useState(false)

	useEffect(()=>{
		async function getUniversities(db) {
			setSpinner(true)
	  		
    	const universityCol = query(collection(db, 'universitied'));
    	const universityTable =  await getDocs(universityCol);
    	setUniversities(universityTable.docs)
	      	
			setSpinner(false)
    }
		getUniversities(firebase.db)
	},[])

	 

  function handleInput(event) {
  	const searchbar = document.querySelector('ion-searchbar');
    const items = Array.from(document.querySelector('ion-list').children);

    const query = event.target.value.toLowerCase();
    requestAnimationFrame(() => {
      items.forEach(item => {
        const shouldShow = item.textContent.toLowerCase().indexOf(query) > -1;
        item.style.display = shouldShow ? 'block' : 'none';
      });
    });
  }

	return(
		<>
			<Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible={spinner}
        className="spinner"
      />

      <IonHeader translucent>
        <IonToolbar>
          <IonSearchbar onIonChange={(e)=>handleInput(e)}></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      	<IonList>
      	{universities.lenght !==0 && universities.map((doc,index) =>
      			<IonItem href={`/university/${doc.id}/${doc.data().slug}`} key={index}>{doc.data().name}</IonItem>
      	)}

      	</IonList>
      

			
      
		</>
	)
}