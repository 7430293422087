import { IonContent } from '@ionic/react';
import React,{useContext} from 'react';

import {Slider} from '../components/HomePage/Slider'
import {Country} from '../components/HomePage/Country'

const Home: React.FC = () => {

  return (
      <>
        <Slider />
        <Country />
      </>
  );
};

export default Home;
