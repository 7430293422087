import React,{useState,useEffect} from 'react';
import { getPlatforms } from '@ionic/react';

import {Desktop,Mobile} from './screen/screen'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const App: React.FC = () => {
  const [mScreen, setMScreen] = useState<any>({
    matches: window.innerWidth > 620 ? true : false,
  });


  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 620px)");
    mediaQuery.addListener(setMScreen);
    return () => mediaQuery.removeListener(setMScreen);
  },[]);

  if(mScreen && mScreen.matches){
    document.body.classList.add('web');
    document.body.classList.remove('mobile');
  }else{
    document.body.classList.add('mobile');
    document.body.classList.remove('web');
  }

  return(
      <>
        {mScreen && mScreen.matches ? (
        <Desktop />
      ) : (
          <Mobile />
      )}
        
      </>
  )

  
};

export default App;
