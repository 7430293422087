import React,{useState,FC,useEffect } from "react";
import { Route, RouteComponentProps, RouteProps, Redirect } from "react-router";

import firebase from '../FirebaseConfig';

import { Wrapper } from "../wrapper/Wrapper";
import { APIContext } from '../context/context';

export const WrapperRoute : FC<RouteProps> = ({ component: Component, ...rest }) => {

	return (
	  <>
      <Route
        {...rest}
            render={(props: RouteComponentProps<{}>) => (<APIContext.Provider value={[firebase]}><Wrapper><Component {...props} /></Wrapper></APIContext.Provider>) }
      />
      </>
    );	
	    
};
