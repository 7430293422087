import { IonButton, IonMenuButton } from "@ionic/react";
import React, { useEffect } from "react";

export const WebNav = () => {
  const [mQuery, setMQuery] = React.useState<any>({
    matches: window.innerWidth > 620 ? true : false,
  });

  useEffect(() => {
    let mediaQuery = window.matchMedia("(min-width: 550px)");
    mediaQuery.addListener(setMQuery);

    return () => mediaQuery.removeListener(setMQuery);
  }, []);

  return (
    <div>
      {mQuery && !mQuery.matches ? (
        <IonMenuButton />
      ) : (
        <>
          <IonButton routerLink={"/"} className="navigation">Home </IonButton>
          <IonButton routerLink={"/blog"} className="navigation">Blog</IonButton>
          <IonButton routerLink={"/page-2"} className="navigation">About us</IonButton>
          <IonButton routerLink={"/page-2"} className="navigation">Contact us</IonButton>
        </>
      )}
    </div>
  );
};
