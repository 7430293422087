import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { homeOutline, laptopOutline, bookOutline, informationCircle, paperPlaneOutline, trailSignOutline } from 'ionicons/icons';

import { IonReactRouter } from '@ionic/react-router';
import Home from '../pages/Home';
import {Blog} from '../pages/Blog';
import {UniversityList} from '../pages/UniversityList';
import {MciList} from '../pages/MciList';
import {UniversityMobileList} from '../pages/mobileview/UniversityList';
import {University} from '../pages/mobileview/University';

import '@ionic/react/css/core.css';

import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '../theme/variables.css';
import { MobileNav } from '../components/MobileNav';

import {WrapperRoute} from '../routes/routes'

export const Desktop: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <MobileNav />
      <IonRouterOutlet  id="main">
        <WrapperRoute path="/" component={Home} exact={true} />
        <WrapperRoute path="/blog" component={Blog} exact={true} />
        <WrapperRoute path="/country/:id/:country_slug" component={UniversityList} exact={true} />
        <Redirect to="/" />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
)

export const Mobile: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      
        <IonTabs>
          <IonRouterOutlet>
            <WrapperRoute path="/:tab(home)" component={Home} exact={true} />
            <WrapperRoute path="/:tab(mci_universities)" component={MciList} exact={true} />
            <WrapperRoute path="/:tab(about)" component={Home} />
            <WrapperRoute path="/:tab(contact)" component={Home} />
            <WrapperRoute path="/:tab(blog)" component={Blog} />
            <WrapperRoute path="/country/:id/:country_slug" component={UniversityMobileList} exact={true} />
            <WrapperRoute path="/university/:id/:university_slug" component={University} exact={true} />
            <Route exact path="/" render={() => <Redirect to="/home" />} />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton tab="home" href="/home" selected>
              <IonIcon icon={homeOutline} />
              <IonLabel>Home</IonLabel>
            </IonTabButton>
            <IonTabButton tab="mci_universities" href="/mci_universities">
              <IonIcon icon={laptopOutline} />
              <IonLabel>MCI Listed</IonLabel>
            </IonTabButton>
            <IonTabButton tab="about" href="/about">
              <IonIcon icon={paperPlaneOutline} />
              <IonLabel>Application Form</IonLabel>
            </IonTabButton>
            <IonTabButton tab="contact" href="/about">
              <IonIcon icon={trailSignOutline} />
              <IonLabel>About us</IonLabel>
            </IonTabButton>
            <IonTabButton tab="blog" href="/blog">
              <IonIcon icon={bookOutline} />
              <IonLabel>Blog</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
     
    </IonReactRouter>
  </IonApp>
);


