import React,{useEffect,useContext,useState} from 'react'
import { IonContent } from '@ionic/react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { APIContext } from '../context/context';
import Loader from "react-loader-spinner";

export const UniversityList = (props) => {
	
	const [firebase] = useContext(APIContext);
	const [universities,setUniversities] = useState([])
	const [country,setCountry] =useState([])
	const [spinner,setSpinner] = useState(false)

	useEffect(()=>{
		async function getUniversities(db) {
			setSpinner(true)
			const countryCol = query(collection(db, 'country'),where("slug", "==", props.match.params.country_slug));
			const countryTable = await getDocs(countryCol);

	  		if(countryTable.docs.length !== 0){
	  			setCountry(countryTable.docs)
		    	const universityCol = query(collection(db, 'universitied'),where("country_id", "==", countryTable.docs[0].id));
		      	const universityTable =  await getDocs(universityCol);
		      	setUniversities(universityTable.docs)
		      	setSpinner(false)
			}
			setSpinner(false)
    	}
    	if(props.match.params.country_slug !== undefined){
			getUniversities(firebase.db)
    	}
	},[props.match.params.country_slug])
	
	return(
		<>
			<Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        visible={spinner}
        className="spinner"
      />
			<div className="page">
				<div className="nesttabs">
					<div className="">
		        <div className="row">
		          <div className="col-4 web">
		            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
		              {universities.length !==0 && universities.map((doc,index) =>
		              	  <a className="nav-link" id={`v-pills-${doc.id}-tab`} data-toggle="pill" href={`#v-pills-${doc.id}`} role="tab" aria-controls={`v-pills-${doc.id}`} aria-selected="false" key={doc.id}>
		                  <div className="descrip" style={{display:'flex'}}>
		                    <h5 className="mb-1">{doc.data().name}</h5>
		                  </div>
		                </a>
		              )}
		            </div>
		          </div>
		          <div className="col-8 nesttabscontent">
		            <div className="tab-content" id="v-pills-tabContent">
		              {universities.length !==0 && universities.map((doc,index)=>
		              	<div className={`tab-pane fade ${index === 0 ? 'active show' : ''}`} id={`v-pills-${doc.id}`} role="tabpanel" aria-labelledby={`v-pills-${doc.id}-tab`} key={doc.id}>
		                  	
		                  	<div className="web_c_button" style={{display:'flex',justifyContent:'flex-end'}}>
								<button className="btn btn-primary">Brochure</button>
								<button className="btn btn-primary">Fees Structure</button>
								<button className="btn btn-primary">Application Form</button>
							</div><br />
							<p>{doc.data().name}</p>
		                  {doc.data().content !== null ? <div className="web content" dangerouslySetInnerHTML={{__html:doc.data().content}} /> : ''}
		              </div>
		              )}	
		            </div>
		          </div>
        		</div>
      		</div>
      	</div>
    	</div>
      
		</>
	)
}