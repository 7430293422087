import React,{useState,useEffect} from 'react'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';

export const Blog = () => {

	const [blogs,setBlog] = useState()

	useEffect(()=>{
		async function getBlogs(){
			fetch(`https://www.googleapis.com/blogger/v3/blogs/1410602142462910920/posts/?fetchImages=true&fetchBodies=true&key=AIzaSyDrhCIuH_HQekFFdYp_HQJNB2z9FVnnRpI`)
		      .then(res => res.json())
		      .then(
		        (result) => {
		        	setBlog(result)
		        },
		        // Note: it's important to handle errors here
		        // instead of a catch() block so that we don't swallow
		        // exceptions from actual bugs in components.
		        (error) => {
		          console.log(error)
		        }
		    )
		}
	    getBlogs()
	},[])

	return(
		<>
			<IonGrid>
	          <IonRow>
	            {blogs !== undefined && blogs.items.length !==0 && blogs.items.map((blogsArray, index) => (
	            <IonCol size-lg="3" size-md="8" size-sm="12" size="12" key={index} >
	              <IonCard>
	                  <img src={blogsArray.images[0].url} />
	               	  <IonCardHeader>
			            <IonCardTitle>{blogsArray.title}</IonCardTitle>
			            <IonCardSubtitle>{blogsArray.displayName}</IonCardSubtitle>
			          </IonCardHeader>
					  <IonCardContent style={{display:'flex',justifyContent:'space-between'}}>
					          <div style={{display:'flex'}}>
					          	<img src={blogsArray.author.image.url} />
					          	<p style={{paddingTop:6,paddingLeft:15}}>{blogsArray.author.displayName}</p>
					          </div>
					          <div>
					          	<p>{blogsArray.published}</p>
					          </div>
					   </IonCardContent>	
	              </IonCard>
	            </IonCol>
	            ))}
	            
	          </IonRow>
	        </IonGrid>
		</>
	)
}