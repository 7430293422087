import React,{useEffect,useState,useContext} from 'react';
import { NavLink } from 'react-router-dom'
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonItem, IonIcon, IonLabel, IonButton, IonGrid, IonRow, IonCol } from '@ionic/react';
import { pin, wifi, wine, warning, walk } from 'ionicons/icons';
import { collection, getDocs } from 'firebase/firestore';
import { APIContext } from '../../context/context';
import Loader from "react-loader-spinner";

export const Country = () => {
  const [firebase] = useContext(APIContext);
	const [countryDetails,setCountryDetails] = useState([]);
  const [spinner,setSpinner] = useState(false)

	useEffect(()=>{
      async function getCountry(db){
        setSpinner(true)
        const countryCol = collection(db, 'country');
        const countryTable = await getDocs(countryCol);
        setCountryDetails(countryTable.docs)
        setSpinner(false)
      }
      
      getCountry(firebase.db)
	},[firebase])

  
  return (
    <>
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          visible={spinner}
          className="spinner"
        />
      	<IonGrid>
          <IonRow>
            {countryDetails.length !== 0 && countryDetails.map((countryDetailsArray, index) => (
            <IonCol size-lg="2" size-md="3" size-sm="4" size="6" key={index} >
              <IonCard>
                <NavLink to={`/country/${countryDetailsArray.id}/${countryDetailsArray.data().slug}`}>
                  <img src={process.env.PUBLIC_URL+`/country/${countryDetailsArray.data().img}`} />
                </NavLink>
              </IonCard>
            </IonCol>
            ))}
            
          </IonRow>
        </IonGrid>


    </>

  );
};