import React,{useState,useEffect,useContext} from 'react'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { APIContext } from '../../context/context';
import Loader from "react-loader-spinner";
import { IonContent,useIonActionSheet,IonButton, IonHeader, IonFooter, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonFabList } from '@ionic/react';
import { add, settings, share, logoIonic, logoNpm, logoPwa, arrowUpCircle, logoVimeo, logoFacebook, logoInstagram, logoTwitter } from 'ionicons/icons';

export const University = (props) => {

	const [firebase] = useContext(APIContext);
	const [universities,setUniversities] = useState([])
	const [country,setCountry] =useState([])
	const [spinner,setSpinner] = useState(false)
	const [present, dismiss] = useIonActionSheet();

	useEffect(()=>{
		async function getUniversities(db) {
			setSpinner(true)
			const universityCol = query(collection(db, 'universitied'),where("__name__", "==", props.match.params.id));
    	const universityTable =  await getDocs(universityCol);
    	setUniversities(universityTable.docs)
    	setSpinner(false)
    }
		getUniversities(firebase.db)
	},[])

	return(
		<>
				<Loader
	        type="Puff"
	        color="#00BFFF"
	        height={100}
	        width={100}
	        visible={spinner}
	        className="spinner"
	      />
				<div className="col-12 nesttabscontent">
            <div className="tab-content" id="v-pills-tabContent">
              {universities.length !==0 && universities.map((doc,index)=>
              	<div className={`tab-pane fade ${index === 0 ? 'active show' : ''}`} id={`v-pills-${doc.id}`} role="tabpanel" aria-labelledby={`v-pills-${doc.id}-tab`} key={doc.id}>
                  <IonButton
					          expand="block"
					          onClick={() =>
					            present({
					              buttons: [{ text: 'Brochure', handler: () => {
							            console.log('Brochure clicked');
							          }}, { text: 'Fees Structure', handler: () => {
							            console.log('Fees clicked');
							          }}, {text : 'Application Form',handler: () => {
							            console.log('Application clicked');
							          }} ],
					            })
					          }
					        >
					         Download Fees Structure
					        </IonButton>
                  {doc.data().content !== null ? <div className="mobile content" dangerouslySetInnerHTML={{__html:doc.data().content}} /> : ''}
              	</div>
              )}	
            </div>
        </div>

        <IonFab vertical="center" horizontal="end" slot="fixed">
	        <IonFabButton>
	          <IonIcon icon={share} />
	        </IonFabButton>

	        <IonFabList side="top">
		        <IonFabButton>
		          <IonIcon icon={logoFacebook} />
		        </IonFabButton>
		        <IonFabButton>
		          <IonIcon icon={logoInstagram} />
		        </IonFabButton>
		        <IonFabButton>
		          <IonIcon icon={logoTwitter} />
		        </IonFabButton>
	      	</IonFabList>

    		</IonFab>
		</>
	)
}